// frontend/src/components/FooterComponent/FooterComponent.js
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PopupComponent from "../PopupComponent/PopupComponent";
import parse from "html-react-parser";

const FooterComponent = () => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const handleOpenPopup = (contentKey) => {
    const htmlContent = t(contentKey + "Content");
    setPopupContent(parse(htmlContent));
    setIsPopupOpen(true);
  };

  useEffect(() => {
    if (isPopupOpen) {
      const popupContent = document.querySelector(".your-popup-content-class");
      if (popupContent) {
        popupContent.scrollTop = 0; // Скролл к верху контента
      }
    }
  }, [isPopupOpen]);

  return (
    <footer className="mt-auto py-4 text-gray-400 dark:text-gray-500 text-xs pb-[env(safe-area-inset-bottom)]">
      <ul className="space-y-2">
        <li>
          <button
            onClick={() => handleOpenPopup("contactInfo")}
            className="hover:text-gray-800 dark:hover:text-white transition-colors duration-200"
          >
            {t("contactInfo")}
          </button>
        </li>
        <li>
          <button
            onClick={() => handleOpenPopup("userAgreement")}
            className="hover:text-gray-800 dark:hover:text-white transition-colors duration-200"
          >
            {t("userAgreement")}
          </button>
        </li>
        <li>
          <button
            onClick={() => handleOpenPopup("dataPolicy")}
            className="hover:text-gray-800 dark:hover:text-white transition-colors duration-200"
          >
            {t("dataPolicy")}
          </button>
        </li>
        <li>
          <button
            onClick={() => handleOpenPopup("feedbackForm")}
            className="hover:text-gray-800 dark:hover:text-white transition-colors duration-200"
          >
            {t("feedbackForm")}
          </button>
        </li>
      </ul>

      <PopupComponent
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      >
        <div className="text-gray-700 hover:text-gray-800 dark:hover:text-gray-800 p-4 overflow-auto">
          {popupContent}
        </div>
      </PopupComponent>
    </footer>
  );
};

export default FooterComponent;
