import React from "react";
import { useTranslation } from "react-i18next";
import CardListComponent from "../components/CardListComponent/CardListComponent";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-10 lg:mt-20">
      <Helmet>
        <title>{t("homepageTitle")}</title>
        <meta name="keywords" content={t("homepageKeywords")} />
        <meta name="description" content={t("homepageDescription")} />
        <meta property="og:title" content={t("homepageTitle")} />
        <meta property="og:description" content={t("homepageDescription")} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="ссылка_на_изображение" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("homepageTitle")} />
        <meta name="twitter:description" content={t("homepageDescription")} />
        <meta name="twitter:image" content="ссылка_на_изображение" />
      </Helmet>
      <CardListComponent language={t.language} />
      <div className="my-10">{parse(t("homepageContent"))}</div>
    </div>
  );
};

export default HomePage;
