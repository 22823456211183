import React, { useEffect, useState, useContext, useRef } from "react";
import {
  getPosts,
  getPostsByCategoryLink,
} from "../../services/firestoreService";
import { LanguageContext } from "../../contexts/LanguageContext";
import CardComponent from "../CardComponent/CardComponent";
import Masonry from "react-masonry-css";
import { useLoader } from "../../contexts/LoaderContext";
import LoaderComponent from "../LoaderComponent/LoaderComponent";
import { useTranslation } from "react-i18next";

const CardListComponent = ({ categoryLink }) => {
  const [posts, setPosts] = useState([]);
  const { language } = useContext(LanguageContext);
  const [lastVisible, setLastVisible] = useState(null);
  const { isLoading, setIsLoading } = useLoader();
  const [hasMore, setHasMore] = useState(true);
  const loadMoreRef = useRef(null); // Создаем ссылку для элемента кнопки "Ещё"
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);

      const result = categoryLink
        ? await getPostsByCategoryLink(categoryLink)
        : await getPosts();

      setPosts(result.posts);
      setLastVisible(result.lastVisible);
      setIsLoading(false);
      setHasMore(result.posts.length === 10);
    };

    fetchPosts();
  }, [categoryLink, language, setIsLoading]);

  const fetchMorePosts = async () => {
    if (!hasMore || isLoading) return;

    setIsLoading(true);
    const result = categoryLink
      ? await getPostsByCategoryLink(categoryLink, lastVisible)
      : await getPosts(lastVisible);

    setPosts((prevPosts) => [...prevPosts, ...result.posts]);
    setLastVisible(result.lastVisible);
    setIsLoading(false);
    setHasMore(result.posts.length === 10);

    // После обновления состояния и рендера новых данных, проскролливаем к кнопке "Ещё"
    loadMoreRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const breakpointColumnsObj = {
    default: 4,
    1536: 3,
    1280: 2,
    640: 1,
  };

  if (isLoading && posts.length === 0) {
    return <LoaderComponent />;
  }

  return (
    <div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="ml-[-30px] flex w-auto"
        columnClassName="pl-[30px] bg-clip-padding"
      >
        {posts.map((post, index) => (
          <div key={index} className="mb-[30px]">
            <CardComponent post={post} language={language} />
          </div>
        ))}
        {hasMore && (
          <div
            ref={loadMoreRef}
            onClick={fetchMorePosts}
            className="cursor-pointer"
          >
            <div className="max-w-md mx-auto rounded-xl shadow-md hover:shadow-xl my-5 not-prose relative bg-slate-50 overflow-hidden dark:bg-slate-800/25 hover:bg-slate-100 hover:dark:bg-slate-800/70 flex items-center justify-center h-48">
              <span className="text-lg leading-tight font-medium text-black dark:text-white">
                {t("loadMore")}
              </span>
            </div>
          </div>
        )}
      </Masonry>
      {isLoading && <LoaderComponent />}
    </div>
  );
};

export default CardListComponent;
