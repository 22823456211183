import { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { ThemeContext } from "../contexts/ThemeContext";

const useAppSettings = () => {
  // Получаем значения из контекстов языка и темы
  const { language, changeLanguage } = useContext(LanguageContext);
  const { theme, toggleTheme } = useContext(ThemeContext);

  return {
    theme,
    toggleTheme,
    language,
    changeLanguage,
  };
};

export default useAppSettings;
