import React from "react";
import { useParams } from "react-router-dom";
import CardListComponent from "../components/CardListComponent/CardListComponent";

const CategoriesPage = () => {
  // Используем useParams для получения link из URL
  const { link } = useParams(); // Убедитесь, что ваш роут настроен на "/:link"

  return (
    <div className="mt-10 lg:mt-20">
      <h1 className="text-2xl font-bold">Новости категории</h1>
      {/* Передаем link в CardListComponent */}
      <CardListComponent categoryLink={link} />
    </div>
  );
};

export default CategoriesPage;
