import React from "react";
import firstImage from "../../assets/images/first.png";
import secondImage from "../../assets/images/second.png";

const DesignComponent = () => {
  return (
    <div className="absolute z-20 top-0 inset-x-0 flex justify-center overflow-hidden pointer-events-none">
      <div className="w-[108rem] flex-none flex justify-end">
        <img
          src={firstImage}
          alt=""
          className="w-[71.75rem] flex-none max-w-none dark:hidden"
          decoding="async"
        />
        <img
          src={secondImage}
          alt=""
          className="w-[90rem] flex-none max-w-none hidden dark:block"
          decoding="async"
        />
      </div>
    </div>
  );
};

export default DesignComponent;
