// frontend/src/components/SearchComponent/SearchComponent.js
import React from "react";
import IconsComponent from "../IconsComponent/IconsComponent";

const SearchComponent = () => {
  return (
    <div className="bg-white dark:bg-slate-900 relative pointer-events-auto">
      <button
        type="button"
        className="w-full flex items-center text-sm leading-6 text-slate-400 rounded-md ring-1 ring-slate-900/10 shadow-sm py-1.5 mb-4 pl-2 pr-3 hover:ring-slate-300 dark:bg-slate-800 dark:highlight-white/5 dark:hover:bg-slate-700"
      >
        <IconsComponent name="search" className="mr-3 flex-none" />
        Quick search...
        <span className="ml-auto pl-3 flex-none text-xs font-semibold">⌘K</span>
      </button>
    </div>
  );
};

export default SearchComponent;
