import React, { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../contexts/LanguageContext";
import {
  getPostByHref,
  getCategoriesByIds,
} from "../services/firestoreService";
import { formatDate, calculateReadTime } from "../utils/utils";
import IconsComponent from "../components/IconsComponent/IconsComponent";
import LoaderComponent from "../components/LoaderComponent/LoaderComponent"; // Убедитесь, что компонент импортирован

const PostPage = () => {
  const { href } = useParams();
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const [post, setPost] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Добавляем состояние загрузки

  useEffect(() => {
    const fetchPostDetails = async () => {
      setIsLoading(true); // Активируем индикатор загрузки
      const loadedPost = await getPostByHref(href);
      if (loadedPost) {
        const loadedCategories = await getCategoriesByIds(
          loadedPost.categoriesId
        );
        setCategories(loadedCategories);
      }
      setPost(loadedPost);
      setIsLoading(false); // Деактивируем индикатор загрузки
    };

    fetchPostDetails();
  }, [href, t]);

  // Показываем индикатор загрузки, если данные еще не загружены
  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <article className="max-w-3xl mt-10 lg:mt-20 mx-auto pt-5 px-4 xl:max-w-none xl:ml-0 xl:mr-[15.5rem] xl:pr-16">
      <img
        className="w-full h-auto max-h-96 object-cover rounded-lg transition duration-300 ease-in-out filter hover:brightness-75"
        src={post.featuredImage}
        alt={post.title[language]}
      />
      <header className="my-8">
        <div className="space-y-2">
          <div className="flex flex-wrap gap-2">
            {categories.map((category) => (
              <Link
                key={category.id}
                to={`/${category.link}`}
                className="inline-block bg-sky-100 text-sky-800 text-xs font-semibold px-4 py-1 rounded-full hover:bg-sky-200 dark:bg-sky-700 dark:text-sky-300 dark:hover:bg-sky-600"
              >
                {category[language]}
              </Link>
            ))}
          </div>
          <h1 className="text-3xl sm:text-4xl font-bold text-slate-900 dark:text-slate-200">
            {post.title[language]}
          </h1>
        </div>
        <p className="mt-4 text-lg text-slate-500 dark:text-slate-400">
          {post.desc[language]}
        </p>
      </header>
      <section>
        <div className="prose dark:prose-dark max-w-none text-lg">
          {post.text[language]}
        </div>
      </section>
      <footer className="mt-8 flex justify-between items-center">
        <div className="text-sm text-slate-500 dark:text-slate-400">
          {formatDate(post.date)} · {calculateReadTime(post.text[language])}{" "}
          {t("reading")}
        </div>
        <div className="flex items-center space-x-4">
          <span className="flex items-center text-slate-500 dark:text-slate-400">
            <IconsComponent
              name="heart"
              className="h-6 w-6 fill-current mr-2"
            />
            {post.like.count} {t("likes")}
          </span>
          <span className="flex items-center text-slate-500 dark:text-slate-400">
            <IconsComponent name="eye" className="h-6 w-6 fill-current mr-2" />
            {post.viewdCount} {t("views")}
          </span>
        </div>
      </footer>
    </article>
  );
};

export default PostPage;
