import { db } from "../firebase/config";
import {
  collection,
  getDocs,
  query,
  where,
  limit,
  startAfter,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";
import DOMPurify from "dompurify";

DOMPurify.addHook("uponSanitizeElement", (node, data) => {
  if (data.tagName === "a") {
    // Удаляем все теги <a> и их содержимое
    return node.parentNode.removeChild(node);
  } else if (data.tagName === "p" && node.parentNode.tagName === "P") {
    // Преобразовываем вложенные <p> в <span>
    const span = document.createElement("span");
    span.innerHTML = node.innerHTML;
    node.parentNode.replaceChild(span, node);
  }
});

DOMPurify.addHook("uponSanitizeShadowNode", (node, data) => {
  // Дополнительные кастомные правила для Shadow DOM, если нужны
});

const cleanHTML = (html) => {
  // Удаляем текст в квадратных скобках и заменяем "Sports.kz" на "Win2024.kz"
  let cleanText = html
    .replace(/\[.*?\]/g, "")
    .replace(/Sports\.kz/g, "Win2024.kz");

  // Удаление одиночных скобок (без удаления текста между парными скобками требует более сложного подхода и может быть не всегда точно с регулярными выражениями)
  cleanText = removeUnpairedBrackets(cleanText);

  // Очищаем текст с использованием DOMPurify
  return DOMPurify.sanitize(cleanText, { ALLOWED_TAGS: ["p", "span"] });
};

// Функция для удаления одиночных скобок
function removeUnpairedBrackets(text) {
  let stack = [];
  let removeIndices = new Set();

  // Первый проход - идентифицируем индексы скобок, которые нужно удалить
  for (let i = 0; i < text.length; i++) {
    if (text[i] === "(") {
      stack.push(i);
    } else if (text[i] === ")") {
      if (stack.length > 0) {
        stack.pop();
      } else {
        removeIndices.add(i);
      }
    }
  }

  // Все индексы, оставшиеся в стеке, отмечаем к удалению
  while (stack.length > 0) {
    removeIndices.add(stack.pop());
  }

  // Второй проход - строим итоговую строку без отмеченных к удалению скобок
  let result = "";
  for (let i = 0; i < text.length; i++) {
    if (!removeIndices.has(i)) {
      result += text[i];
    }
  }

  return result;
}

export const getPosts = async (lastVisible = null) => {
  console.log(`Getting posts, lastVisible: ${lastVisible}`);
  const postsCollectionRef = collection(db, "Posts");
  let q = query(postsCollectionRef, orderBy("date"), limit(10));
  if (lastVisible) {
    q = query(
      postsCollectionRef,
      orderBy("date"),
      startAfter(lastVisible),
      limit(10)
    );
  }

  const querySnapshot = await getDocs(q);
  const lastVisiblePost = querySnapshot.docs[querySnapshot.docs.length - 1];
  const posts = querySnapshot.docs.map((doc) => {
    const data = doc.data();
    // Применяем cleanHTML к HTML-содержимому
    ["title", "desc", "text"].forEach((field) => {
      Object.keys(data[field]).forEach((lang) => {
        data[field][lang] = cleanHTML(data[field][lang]);
      });
    });
    return { id: doc.id, ...data };
  });
  return { posts, lastVisible: lastVisiblePost };
};

export const getPostByHref = async (href) => {
  const postsRef = collection(db, "Posts");
  const q = query(postsRef, where("href", "==", href));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.log("No such document with the given href!");
    return null;
  }

  // Предполагаем, что href уникален, поэтому возвращаем первый (и единственный) документ
  const postSnap = querySnapshot.docs[0];
  const postData = postSnap.data();

  // Применяем cleanHTML к HTML-содержимому
  ["title", "desc", "text"].forEach((field) => {
    if (postData[field]) {
      Object.keys(postData[field]).forEach((lang) => {
        postData[field][lang] = cleanHTML(postData[field][lang]);
      });
    }
  });

  return { id: postSnap.id, ...postData };
};

export const getCategories = async () => {
  try {
    const categoriesCollectionRef = collection(db, "categories");
    // Добавляем сортировку по полю sort в порядке возрастания
    const q = query(categoriesCollectionRef, orderBy("sort"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Ошибка при получении категорий:", error);
    return [];
  }
};

// Добавляем функцию для получения ID категории по link
export const getCategoryIdByLink = async (link) => {
  const categoriesCollectionRef = collection(db, "categories");
  const q = query(categoriesCollectionRef, where("link", "==", link));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const categoryId = querySnapshot.docs[0].id;
    console.log(`Found categoryId for link "${link}": ${categoryId}`);
    return categoryId;
  }

  console.log(`Category not found for link: ${link}`);
  return null;
};

export const getPostsByCategoryLink = async (link, lastVisible = null) => {
  console.log(
    `getPostsByCategoryLink called with link: ${link} and lastVisible: ${lastVisible}`
  );
  const categoryIdStr = await getCategoryIdByLink(link);
  console.log(`Found categoryIdStr for link "${link}": ${categoryIdStr}`);

  const categoryId = Number(categoryIdStr);
  console.log(`Converted categoryId to number: ${categoryId}`);

  if (!categoryId) {
    console.error("Category not found for link:", link);
    return { posts: [], lastVisible: null };
  }

  const postsCollectionRef = collection(db, "Posts");
  let q = query(
    postsCollectionRef,
    where("categoriesId", "array-contains", categoryId),
    orderBy("date"),
    limit(10)
  );

  if (lastVisible) {
    q = query(
      postsCollectionRef,
      where("categoriesId", "array-contains", categoryId),
      orderBy("date"),
      startAfter(lastVisible),
      limit(10)
    );
  }

  const querySnapshot = await getDocs(q);
  const lastVisiblePost = querySnapshot.docs[querySnapshot.docs.length - 1];
  const posts = querySnapshot.docs.map((doc) => {
    const data = doc.data();
    // Применяем cleanHTML к HTML-содержимому
    ["title", "desc", "text"].forEach((field) => {
      if (data[field]) {
        Object.keys(data[field]).forEach((lang) => {
          data[field][lang] = cleanHTML(data[field][lang]);
        });
      }
    });
    return { id: doc.id, ...data };
  });
  return { posts, lastVisible: lastVisiblePost };
};

// В файле firestoreService.js

export const getCategoriesByIds = async (categoryIds) => {
  const categoriesCollectionRef = collection(db, "categories");
  const promises = categoryIds.map(async (categoryId) => {
    // Убедитесь, что categoryId является строкой
    const docRef = doc(categoriesCollectionRef, String(categoryId));
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.log("No such category for ID:", categoryId);
      return null;
    }
  });

  const results = await Promise.all(promises);
  return results.filter((category) => category !== null);
};
