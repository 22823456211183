import React, { useEffect } from "react";
import useAppSettings from "../../hooks/useAppSettings";
import IconsComponent from "../IconsComponent/IconsComponent";
import styles from "./ThemeSwitcher.module.scss"; // Импорт стилей как модуля

const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useAppSettings();

  // Эффект для чтения сохраненной темы при монтировании компонента
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme && savedTheme !== theme) {
      // Если сохраненная тема отличается от текущей, переключаем тему
      toggleTheme();
    }
  }, [theme, toggleTheme]);

  // Обработчик переключения темы
  const handleToggleTheme = () => {
    toggleTheme();
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
  };

  return (
    <div
      className={`${styles.dark_mode_toggle_wrapper} dark:bg-slate-700/75 rounded-xl shadow`}
    >
      <input
        type="checkbox"
        id="darkmode-toggle"
        className={styles.checkbox}
        checked={theme === "dark"}
        onChange={handleToggleTheme}
      />
      <label
        htmlFor="darkmode-toggle"
        className={`${styles.label} hover:bg-slate-900/15 dark:hover:bg-white/15`}
      >
        {theme === "light" ? (
          <>
            <IconsComponent name="sun" className={styles.sun} />
            <span className={styles["sun"]}>Lumos!</span>
          </>
        ) : (
          <>
            <IconsComponent name="moon" className={styles.moon} />
            <span className={styles["moon"]}>Nox!</span>
          </>
        )}
      </label>
    </div>
  );
};

export default React.memo(ThemeSwitcher);
