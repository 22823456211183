// frontend/src/components/PopupComponent/PopupComponent.js
import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const PopupComponent = ({ children, isOpen, onClose }) => {
  useEffect(() => {
    const toggleBodyScroll = isOpen ? "hidden" : "unset";
    document.body.style.overflow = toggleBodyScroll;

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose(); // Исправлено на использование пропса onClose
      }
    };

    // Добавление и удаление обработчика нажатия клавиши Escape
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]); // Исправлено на добавление onClose в зависимости useEffect

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white p-4 rounded-lg relative max-h-[80vh] overflow-y-auto w-full max-w-3xl m-4"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
        >
          {/* Иконка закрытия (X) */}
          <svg
            className="w-6 h-6"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default PopupComponent;
