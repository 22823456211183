import React, { useState, useEffect } from "react";
import { firebase } from "../../firebase/config";
import { useTranslation } from "react-i18next";
import "firebase/compat/auth";

export default function PhoneAuth({ onAuthSuccess }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoaded, setButtonLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (document.getElementById("sign-in-button")) {
      setButtonLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (buttonLoaded) {
      setUpRecaptcha();
    }
  }, [buttonLoaded]);

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          console.log("Recaptcha solved", response);
        },
        "expired-callback": () => {
          console.error("Recaptcha expired");
        },
      }
    );
    window.recaptchaVerifier.render().catch((error) => {
      console.error("Recaptcha rendering error:", error);
    });
  };

  const handleSendCode = () => {
    setError("");
    setLoading(true);
    const appVerifier = window.recaptchaVerifier;
    if (appVerifier) {
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setVerificationId(confirmationResult.verificationId);
          setLoading(false);
          console.log("SMS sent.");
        })
        .catch((error) => {
          console.error("Error sending SMS:", error);
          setError(t("auth.errorSendingSMS"));
          setLoading(false);
        });
    } else {
      setError("Recaptcha not set up properly.");
      setLoading(false);
    }
  };

  const handleVerifyCode = () => {
    setError("");
    setLoading(true);
    const credential = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      code
    );
    firebase
      .auth()
      .signInWithCredential(credential)
      .then(() => {
        console.log("Phone number verified and user signed in");
        setLoading(false);
        onAuthSuccess();
      })
      .catch((error) => {
        console.error("Failed to verify the phone number", error);
        setError(t("auth.failedVerifyPhone"));
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col justify-center items-center bg-gray-50 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {verificationId ? t("auth.verify") : t("auth.signIn")}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {verificationId ? t("auth.enterCode") : t("auth.receiveCode")}
          </p>
          {error && <p className="text-red-500 text-center">{error}</p>}
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <div className="rounded-md shadow-sm -space-y-px">
            {!verificationId ? (
              <>
                <div>
                  <label htmlFor="phone-number" className="sr-only">
                    {t("auth.enterPhone")}
                  </label>
                  <input
                    id="phone-number"
                    name="phone"
                    type="text"
                    autoComplete="phone"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder={t("auth.enterPhone")}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    disabled={loading}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    onClick={handleSendCode}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-b-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={loading}
                  >
                    {loading ? t("auth.sending") : t("auth.sendCode")}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div>
                  <label htmlFor="verification-code" className="sr-only">
                    {t("auth.enterCode")}
                  </label>
                  <input
                    id="verification-code"
                    name="code"
                    type="text"
                    autoComplete="one-time-code"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder={t("auth.enterCode")}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    disabled={loading}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    onClick={handleVerifyCode}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-b-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={loading}
                  >
                    {loading ? t("auth.verifying") : t("auth.verifyCode")}
                  </button>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
