import React, { createContext, useContext, useState, useEffect } from "react";

// Создание контекста для управления темой приложения
export const ThemeContext = createContext();

// Создание кастомного хука для удобства использования контекста
export const useTheme = () => useContext(ThemeContext);

// Провайдер контекста, который будет обертывать часть или всё приложение
export const ThemeProvider = ({ children }) => {
  // Инициализация состояния темы с чтением из localStorage или установка по умолчанию
  const [theme, setTheme] = useState(
    () => localStorage.getItem("theme") || "light"
  );

  // Побочный эффект для сохранения выбранной темы в localStorage
  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.documentElement.className = theme; // Добавление класса к <html> для управления стилями
  }, [theme]);

  useEffect(() => {
    const baseClasses =
      "antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900";
    document.body.className = baseClasses;
  }, [theme]); // Обновляем классы body при смене темы

  // Функция для изменения темы
  const toggleTheme = () =>
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));

  // Предоставление состояния и методов изменения через контекст
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
