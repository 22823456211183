import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import NavigationComponent from "../NavigationComponent/NavigationComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import IconsComponent from "../IconsComponent/IconsComponent";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import SvgLogo from "../SvgLogo/SvgLogo";
import SvgText from "../SvgLogo/SvgText";
import PopupComponent from "../PopupComponent/PopupComponent"; // Импорт PopupComponent
import AuthComponent from "../AuthComponent/AuthComponent"; // Импорт AuthComponent
import { firebase } from "../../firebase/config";
import "firebase/compat/auth";

const HeaderComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAuthPopupOpen, setIsAuthPopupOpen] = useState(false); // Для управления попапом аутентификации
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const menuRef = useRef(null);
  const backdropRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    const handleKeyDown = (event) => {
      // Проверяем, нажата ли клавиша Escape
      if (event.key === "Escape") {
        setIsMenuOpen(false);
      }
    };

    // Добавляем обработчики событий
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    // Убираем обработчики событий при размонтировании компонента
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? "hidden" : "";
    document.documentElement.style.overflow = isMenuOpen ? "hidden" : "";
  }, [isMenuOpen]);

  const toggleAuthPopup = () => setIsAuthPopupOpen(!isAuthPopupOpen); // Функция для переключения попапа аутентификации

  useEffect(() => {
    // Функция для обновления ширины backdrop
    const updateBackdropWidth = () => {
      const masonry = document.getElementById("masonry");
      if (masonry && backdropRef.current) {
        const masonryWidth = masonry.offsetWidth; // Получаем ширину masonry
        const backdropWidth = masonryWidth * 0.8; // Вычисляем 80% от ширины masonry
        backdropRef.current.style.width = `${backdropWidth}px`; // Устанавливаем ширину для backdrop
      }
    };

    updateBackdropWidth(); // Вызываем при монтировании компонента

    // Обновляем при изменении размера окна
    window.addEventListener("resize", updateBackdropWidth);
    return () => window.removeEventListener("resize", updateBackdropWidth);
  }, []);

  const headerClass =
    "fixed top-2 lg:top-5 z-40 mx-auto max-w-screen-2xl px-4 sm:px-6 md:px-8 inset-x-0 lg:pl-[21.5rem] flex";
  const backdropClass = `mx-10 backdrop-blur rounded-3xl transition-colors duration-500 lg:z-50 lg:border lg:border-slate-600/5 dark:border-slate-50/[0.06] supports-backdrop-blur:bg-white/95 ${
    isScrolled ? "bg-white dark:bg-slate-800/75" : "bg-transparent"
  }`;
  const menuClass =
    "lg:hidden absolute top-full left-0 right-0 bg-white dark:bg-slate-800/90 overflow-y-auto z-50 w-[80%] pb-[100px] max-h-[calc(100vh-10rem)] mt-3 rounded-2xl mx-auto";

  const onAuthSuccess = () => {
    setIsAuthenticated(true);
    setIsAuthPopupOpen(false);
    // Здесь также можно выполнить другие действия, например, сохранить данные пользователя
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setIsAuthenticated(false);
        console.log("User signed out.");
        // Очистка данных пользователя из хранилища, если это необходимо
      });
  };

  return (
    <>
      {isMenuOpen && (
        <style>
          {`
          body::before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.1);
            z-index: 1;
            backdrop-filter: url(filters.svg#filter) blur(3px) saturate(50%);
            -webkit-backdrop-filter: blur(3px);
            filter: grayscale(0%);
            filter: none; /* IE 6-9 */
            -webkit-filter: grayscale(0%);
          }
        `}
        </style>
      )}
      <header className={headerClass}>
        <div className={backdropClass} ref={backdropRef}>
          <div className="relative flex items-center justify-between">
            <Link to="/" className="flex mr-3 items-center justify-center h-10">
              <SvgLogo />
              <SvgText />
            </Link>
            <div className="hidden lg:flex items-center ml-auto mr-2">
              <ThemeSwitcher />
              <LanguageSwitcher />
              {isAuthenticated ? (
                <button
                  onClick={handleLogout}
                  className="ml-4 dark:bg-slate-900/25 rounded-xl shadow hover:bg-slate-900/15 dark:hover:bg-white/15"
                >
                  <IconsComponent name="logout" />
                </button>
              ) : (
                <button
                  onClick={toggleAuthPopup}
                  className="ml-4 dark:bg-slate-900/25 rounded-xl shadow hover:bg-slate-900/15 dark:hover:bg-white/15"
                >
                  <IconsComponent name="auth" />
                </button>
              )}
            </div>
            <div className="flex lg:hidden items-center ml-auto mr-2">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-label="Toggle menu"
                className="ml-4 text-slate-500 hover:text-slate-600"
              >
                <IconsComponent name={isMenuOpen ? "chevronDown" : "burger"} />
              </button>
              {isAuthenticated ? (
                <button
                  onClick={handleLogout}
                  className="ml-4 dark:bg-slate-900/25 rounded-xl shadow hover:bg-slate-900/15 dark:hover:bg-white/15"
                >
                  <IconsComponent name="logout" />
                </button>
              ) : (
                <button
                  onClick={toggleAuthPopup}
                  className="ml-4 dark:bg-slate-900/25 rounded-xl shadow hover:bg-slate-900/15 dark:hover:bg-white/15"
                >
                  <IconsComponent name="auth" />
                </button>
              )}
            </div>
          </div>
          {isMenuOpen && (
            <div ref={menuRef} className={menuClass}>
              <div className="mx-auto w-[80%] mt-5">
                <div className="flex items-center justify-between pb-5 max-w-60 mx-auto">
                  <ThemeSwitcher />
                  <LanguageSwitcher />
                </div>
                <NavigationComponent />
                <FooterComponent />
              </div>
            </div>
          )}
        </div>
      </header>
      <PopupComponent
        isOpen={isAuthPopupOpen}
        onClose={() => setIsAuthPopupOpen(false)}
      >
        <AuthComponent onAuthSuccess={onAuthSuccess} />
      </PopupComponent>
    </>
  );
};

export default HeaderComponent;
