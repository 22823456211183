import React from "react";

// Иконка солнца
const SunIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    className={className}
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
    />
  </svg>
);

const MoonIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    className={className}
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
    />
  </svg>
);

const BurgerIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    className={className}
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
    />
  </svg>
);

const WorldIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    className={className}
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
    />
  </svg>
);

const SearchIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    className={className}
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
    />
  </svg>
);

const HomeIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    className={className}
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819"
    />
  </svg>
);
const chevronUpIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    className={className}
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m4.5 15.75 7.5-7.5 7.5 7.5"
    />
  </svg>
);
const chevronDownIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m19.5 8.25-7.5 7.5-7.5-7.5"
    />
  </svg>
);
const winterIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="M22.194,20.865a1.985,1.985,0,0,1-1.139,1.021,2.008,2.008,0,0,1-1.559-.1l-2.5-1.1V14.544l-3.063-2.662c.026-.029,2.237-3.04,2.237-3.04l6.289,4,1.074-1.688L12.291,4H8.414L5.707,1.293,4.293,2.707,7.586,6h3.227L8.621,8.852A3.018,3.018,0,0,0,8,10.68v2.7L4.459,15.171l-1.3-.572a2,2,0,0,1-.973-2.655L.371,11.1a4,4,0,0,0,1.966,5.318l16.324,7.185A4,4,0,0,0,24,21.729ZM15,19.81l-8.182-3.6L10,14.618V11.107l5,4.349ZM17.616-.019a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,17.616-.019Z" />
  </svg>
);

const waterIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="M18,5a3,3,0,1,1,3,3A3,3,0,0,1,18,5ZM3.5,4H9.111a2.5,2.5,0,0,1,2.016,1.03L3.64,10.271a1.5,1.5,0,0,0,1.72,2.458l7.493-5.245,3.418,4.877a1.5,1.5,0,0,0,2.458-1.722l-5.114-7.3A5.507,5.507,0,0,0,9.111,1H3.5a1.5,1.5,0,0,0,0,3ZM.747,18.4A4.76,4.76,0,0,0,5.8,18.1a.332.332,0,0,1,.406,0,4.91,4.91,0,0,0,5.594,0,.332.332,0,0,1,.406,0,4.91,4.91,0,0,0,5.594,0,.332.332,0,0,1,.406,0A4.88,4.88,0,0,0,21,19a4.491,4.491,0,0,0,2.253-.605A1.5,1.5,0,0,0,21.747,15.8a1.7,1.7,0,0,1-1.8-.149,3.35,3.35,0,0,0-3.882,0,1.85,1.85,0,0,1-2.118,0,3.35,3.35,0,0,0-3.882,0,1.85,1.85,0,0,1-2.118,0,3.35,3.35,0,0,0-3.882,0,1.7,1.7,0,0,1-1.807.15A1.5,1.5,0,0,0,.747,18.4Zm21,2.405a1.7,1.7,0,0,1-1.8-.149,3.35,3.35,0,0,0-3.882,0,1.85,1.85,0,0,1-2.118,0,3.35,3.35,0,0,0-3.882,0,1.85,1.85,0,0,1-2.118,0,3.35,3.35,0,0,0-3.882,0,1.7,1.7,0,0,1-1.807.15A1.5,1.5,0,0,0,.747,23.4,4.762,4.762,0,0,0,5.8,23.1a.332.332,0,0,1,.406,0,4.91,4.91,0,0,0,5.594,0,.332.332,0,0,1,.406,0,4.91,4.91,0,0,0,5.594,0,.332.332,0,0,1,.406,0A4.88,4.88,0,0,0,21,24a4.491,4.491,0,0,0,2.253-.605A1.5,1.5,0,0,0,21.747,20.8Z" />
  </svg>
);

const motoIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="M22.933,14.056,22.605,13H13.193l.608-1.7a.4.4,0,0,1,.285-.3l4.4-.48A2.941,2.941,0,0,0,20.819,6.6C19.373,2.651,15.253,0,10.568,0A10.58,10.58,0,0,0,0,10.568,48.789,48.789,0,0,0,.769,16.8a3.391,3.391,0,0,0,2.414,2.7,33.455,33.455,0,0,1,8.033,3.33l1.016.623,2.716-2.757,1.869,2.088.284.131A13.421,13.421,0,0,0,22.5,24H24V22.5A31,31,0,0,0,22.933,14.056ZM11.747,19.665a38.642,38.642,0,0,0-7.718-3.049.413.413,0,0,1-.3-.32A45.22,45.22,0,0,1,3,10.568,7.577,7.577,0,0,1,10.568,3c3.421,0,6.4,1.849,7.43,4.554l-4.29.468a3.4,3.4,0,0,0-2.795,2.463L10.1,12.749a3.4,3.4,0,0,0,.323,2.476l2.562,3.181Zm6.878.634-2.482-2.771L14.9,16H20.33a30.346,30.346,0,0,1,.63,4.9A9.752,9.752,0,0,1,18.625,20.3ZM9,14.5A1.5,1.5,0,1,1,7.5,13,1.5,1.5,0,0,1,9,14.5Z" />
  </svg>
);

const tennisIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="M21.922,2.078A7.455,7.455,0,0,0,15.73.05,10.864,10.864,0,0,0,9.257,3.258,10.863,10.863,0,0,0,6.049,9.731a14.665,14.665,0,0,0-.023,1.506,8.042,8.042,0,0,0,.157,1.176c.142,1.571.107,3.883-.891,4.88l-5.25,5.25,1.414,1.414,5.25-5.25c1.006-1,3.344-1.032,4.915-.887a16.4,16.4,0,0,0,1.7.182,8.987,8.987,0,0,0,.949-.051,10.869,10.869,0,0,0,6.474-3.208A10.875,10.875,0,0,0,23.95,8.27,7.451,7.451,0,0,0,21.922,2.078Zm.039,5.983a7.949,7.949,0,0,1-.555,2.171L19.923,8.749,21.968,6.7A6.7,6.7,0,0,1,21.961,8.061ZM21.3,4.544,18.509,7.335,16.583,5.409l2.753-2.753a4.87,4.87,0,0,1,1.172.836A4.831,4.831,0,0,1,21.3,4.544ZM9.49,14.51a4.868,4.868,0,0,1-.847-1.16l2.991-2.991,1.925,1.926L10.543,15.3A4.889,4.889,0,0,1,9.49,14.51ZM8.732,7.457,10.22,8.945,8.038,11.126A6.92,6.92,0,0,1,8.732,7.457Zm4.316,1.488,2.121-2.122L17.1,8.749,14.973,10.87Zm2.89-6.906a4.6,4.6,0,0,1,1.187,0L15.169,4l-1.4-1.4A8.461,8.461,0,0,1,15.938,2.039ZM11.945,3.6l1.81,1.81L11.634,7.531,9.791,5.688A9.593,9.593,0,0,1,11.945,3.6ZM14.06,15.962a9.709,9.709,0,0,1-1.327-.023l2.24-2.24,1.57,1.569A8.054,8.054,0,0,1,14.06,15.962Zm4.252-1.753-1.924-1.924,2.121-2.122L20.4,12.054a9.629,9.629,0,0,1-2.089,2.155Z" />
  </svg>
);

const basketballIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="M16.252,6.334A9.223,9.223,0,0,1,14,.181a11.95,11.95,0,0,1,5.741,2.664Zm-8.5,8.5L10.586,12,2.845,4.259A11.944,11.944,0,0,0,0,12H.323A11.582,11.582,0,0,1,7.749,14.837ZM21.155,4.259,17.666,7.748A9.531,9.531,0,0,0,23.819,10,11.95,11.95,0,0,0,21.155,4.259Zm-6.318,3.49A11.585,11.585,0,0,1,12,.323V0A11.944,11.944,0,0,0,4.259,2.845L12,10.586ZM23.677,12a11.584,11.584,0,0,1-7.426-2.837L13.414,12l7.741,7.741A11.944,11.944,0,0,0,24,12ZM9.163,16.251A11.937,11.937,0,0,1,12,23.648V24h0a11.944,11.944,0,0,0,7.741-2.845L12,13.414ZM7.746,17.668,4.259,21.155A11.95,11.95,0,0,0,10,23.819,9.495,9.495,0,0,0,7.746,17.668ZM.181,14a11.95,11.95,0,0,0,2.664,5.741l3.489-3.489A9.452,9.452,0,0,0,.181,14Z" />
  </svg>
);

const hockeyIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="m22.187.026l-8.664,16.974H3.5c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5h6.246c1.511,0,2.875-.836,3.562-2.181L23.968.936,22.187.026Zm1.813,21.974v2h-6v-2h6Z" />
  </svg>
);

const figureIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="m19,24H1c-.553,0-1-.447-1-1s.447-1,1-1h18c1.654,0,3-1.346,3-3,0-.553.447-1,1-1s1,.447,1,1c0,2.757-2.243,5-5,5Zm.998-8.001c0,1.068-.415,2.074-1.171,2.829-.755.756-1.76,1.172-2.829,1.172H4c-1.21,0-2.347-.535-3.118-1.468C.114,17.604-.196,16.395.029,15.215c.163-.85.309-1.45.309-1.45L2.654,2.618C2.964,1.123,4.296.038,5.822.038h6.908c.846,0,1.637.377,2.169,1.034.532.658.736,1.51.561,2.338l-.134.59h-4.326c-.553,0-1,.447-1,1s.447,1,1,1h3.871l-.455,2h-4.417c-.553,0-1,.447-1,1s.447,1,1,1h4.708l.713.206c2.734.568,4.656,2.997,4.577,5.793Zm-12.998-1.499c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5,1.5.672,1.5,1.5,1.5,1.5-.672,1.5-1.5Z" />
  </svg>
);

const bettingIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="M20.5,9h-8A3.5,3.5,0,0,0,9,12.5V24H24V12.5A3.5,3.5,0,0,0,20.5,9ZM21,21H12V12.5a.5.5,0,0,1,.5-.5h8a.5.5,0,0,1,.5.5Zm-5-6.5A1.5,1.5,0,1,1,14.5,13,1.5,1.5,0,0,1,16,14.5ZM8,10A1.5,1.5,0,1,1,6.5,8.5,1.5,1.5,0,0,1,8,10Zm2-2a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,10,8ZM20,18.5A1.5,1.5,0,1,1,18.5,17,1.5,1.5,0,0,1,20,18.5ZM3.147,10.217,7,14.07v4.243L1.025,12.338a3.5,3.5,0,0,1,0-4.95L7.389,1.024A3.476,3.476,0,0,1,9.863,0h0a3.48,3.48,0,0,1,2.475,1.024L18.314,7H14.072L10.218,3.146a.512.512,0,0,0-.707,0L3.146,9.51a.5.5,0,0,0,0,.707Z" />
  </svg>
);

const esportsIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="M17.7,3H6.3C4.4,3,2.7,4,1.7,5.7C0.6,7.6,0,9.8,0,12c0,4.9,2,10,5.3,10c1.8,0,3.3-1.4,4-3.7C9.4,18.1,9.5,18,9.7,18h4.6  c0.2,0,0.4,0.1,0.4,0.3c1,3.2,2.9,3.7,3.9,3.7C22,22,24,16.9,24,12c0-2.2-0.6-4.4-1.7-6.4C21.4,4,19.6,3,17.7,3z M18.7,19  c-0.3,0-0.7-0.5-1-1.6C17.2,16,15.9,15,14.4,15H9.7c-1.5,0-2.8,1-3.3,2.4c-0.3,1-0.8,1.6-1.1,1.6C4.7,18.9,3,16.4,3,12  c0-1.7,0.5-3.4,1.3-4.9C4.7,6.4,5.5,6,6.3,6h11.4c0.8,0,1.6,0.4,2,1.1C20.6,8.6,21,10.3,21,12C21,16.4,19.3,18.9,18.7,19z" />
    <path d="M10.5,9H10V8.5C10,7.7,9.3,7,8.5,7S7,7.7,7,8.5V9H6.5C5.7,9,5,9.7,5,10.5S5.7,12,6.5,12H7v0.5C7,13.3,7.7,14,8.5,14  s1.5-0.7,1.5-1.5V12h0.5c0.8,0,1.5-0.7,1.5-1.5S11.3,9,10.5,9z" />
    <circle cx="17" cy="10" r="2" />
  </svg>
);

const politicsIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="m7,5c0-2.757,2.243-5,5-5s5,2.243,5,5-2.243,5-5,5-5-2.243-5-5Zm17,15H0v2h2v2h20v-2h2v-2Zm-12.586-6.379l-1.747-2.621h-.667c-2.757,0-5,2.243-5,5v2h6.196l1.218-4.379Zm2.919-2.621l-1.747,2.621,1.218,4.379h6.196v-2c0-2.757-2.243-5-5-5h-.667Z" />
  </svg>
);

const boxingIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="M20,7.256A7.509,7.509,0,0,0,12.5,0h-4A7.508,7.508,0,0,0,1,7.5v6.144A8.535,8.535,0,0,0,3.643,19.8l.357.34V21a3,3,0,0,0,3,3h9a3,3,0,0,0,3-3v-.3l.96-.8A8.482,8.482,0,0,0,23,13.381V11.5A4.508,4.508,0,0,0,20,7.256Zm0,6.125A5.489,5.489,0,0,1,18.032,17.6s-1.6,1.35-1.649,1.4H6.914l-1.2-1.37A5.526,5.526,0,0,1,4,13.644v-1.9A4.488,4.488,0,0,0,5.5,12h5a1.5,1.5,0,0,0,0-3h-5A1.5,1.5,0,0,1,4,7.5,4.505,4.505,0,0,1,8.5,3h4a4.506,4.506,0,0,1,4.493,4.259A4.509,4.509,0,0,0,14,11.5v3a1.5,1.5,0,0,0,3,0v-3a1.5,1.5,0,0,1,3,0Z" />
  </svg>
);

const soccerIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 164.83 151.5"
    strokeWidth={7}
    stroke="currentColor"
    className={className}
  >
    <path d="M117.24,69.24A8,8,0,0,0,115.67,67c-4.88-4-9.8-7.89-14.86-11.62A4.93,4.93,0,0,0,96.93,55c-5.76,1.89-11.4,4.17-17.18,6a4.36,4.36,0,0,0-3.42,4.12c-1,6.89-2.1,13.76-3,20.66a4,4,0,0,0,1,3.07c5.12,4.36,10.39,8.61,15.68,12.76a3.62,3.62,0,0,0,2.92.75c6.29-2.66,12.52-5.47,18.71-8.36a3.49,3.49,0,0,0,1.68-2.19c1.34-7.25,2.54-14.55,3.9-22.58Z" />
    <path d="M97.55,38.68A43.76,43.76,0,0,1,98,33.44c.41-2.36-.5-3.57-2.57-4.64C91.1,26.59,87,24,82.66,21.82a6.18,6.18,0,0,0-4-.71C73.45,22.55,68.32,24.25,63.22,26c-3.63,1.21-6.08,3.35-5.76,7.69a26.67,26.67,0,0,1-.6,4.92c-1.08,8.06-1.08,8.08,5.86,11.92,3.95,2.19,7.82,5.75,11.94,6.08s8.76-2.41,13.12-3.93c9.33-3.29,9.33-3.3,9.78-14Z" />
    <path d="M66.11,126.56c5.91-.91,11.37-1.7,16.81-2.71a3.3,3.3,0,0,0,1.87-2.17c1-4.06,1.73-8.19,2.84-12.24.54-2-.11-3-1.55-4.15-5-4-9.9-8.12-15-12a6.19,6.19,0,0,0-4.15-1.1c-5.35.66-10.7,1.54-16,2.54A4,4,0,0,0,48.34,97a109.13,109.13,0,0,0-3,12.19,4.47,4.47,0,0,0,1.34,3.6c5.54,4.36,11.23,8.53,16.91,12.69a10.84,10.84,0,0,0,2.57,1.11Z" />
    <path d="M127.42,104.12c4.1-2.1,8-3.93,11.72-6a6,6,0,0,0,2.27-3,58.22,58.22,0,0,0,3.18-29.92c-.26-1.7-8-7.28-9.71-6.85A5,5,0,0,0,133,59.65c-2.81,2.49-5.71,4.88-8.33,7.56a9.46,9.46,0,0,0-2.47,4.4c-1.29,6.49-2.38,13-3.35,19.55a5.73,5.73,0,0,0,.83,3.91c2.31,3.08,5,5.88,7.7,9Z" />
    <path d="M52.58,29.89c-2.15-.36-3.78-.54-5.39-.9-2.83-.64-4.92.1-7,2.32A64.1,64.1,0,0,0,26.09,54.64c-2.64,7.92-2.62,7.84,5.15,10.87,1.76.69,2.73.45,3.93-1C39.79,59,44.54,53.65,49.22,48.2a4.2,4.2,0,0,0,1.13-2c.8-5.32,1.49-10.68,2.24-16.34Z" />
    <path d="M23,68.13c0,2.51,0,4.7,0,6.87a60.49,60.49,0,0,0,9.75,32.15c1.37,2.13,6.4,3,7,1.2,1.55-5,2.68-10.2,3.82-15.34.13-.58-.58-1.38-.94-2.06-2.51-4.77-5.47-9.38-7.45-14.37C32.94,71,28.22,69.84,23,68.13Z" />
    <path d="M83.91,12.86c-.32.36-.66.71-1,1.07.9,1.13,1.57,2.62,2.73,3.33,4.71,2.84,9.56,5.48,14.39,8.1a9.29,9.29,0,0,0,3.13.83c5.45.69,10.89,1.38,16.35,1.94a10.41,10.41,0,0,0,3.07-.71c-11.48-9.9-24.26-14.61-38.71-14.56Z" />
    <path d="M66.28,132.51c13.36,3.78,25.62,3.5,38-.9C91.68,129.59,79.36,128,66.28,132.51Z" />
    <path d="M127.2,30.66l-1.27.37a18.58,18.58,0,0,0,1,3.08c3,5.52,6.21,10.89,8.89,16.54,1.34,2.83,3.41,3.82,6.49,4.9a60.38,60.38,0,0,0-15.12-24.9Z" />
    <path d="M117.35,125c5.58-2.32,16.9-13.84,18.1-19.2-2.41,1.46-5.18,2.36-6.78,4.23-4.21,5-7.89,10.37-11.32,15Z" />
  </svg>
);

const eyeIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1}
    stroke="currentColor"
    className={className}
  >
    <path d="M23.271,9.419C21.72,6.893,18.192,2.655,12,2.655S2.28,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162C2.28,17.107,5.808,21.345,12,21.345s9.72-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419Zm-1.705,4.115C20.234,15.7,17.219,19.345,12,19.345S3.766,15.7,2.434,13.534a2.918,2.918,0,0,1,0-3.068C3.766,8.3,6.781,4.655,12,4.655s8.234,3.641,9.566,5.811A2.918,2.918,0,0,1,21.566,13.534Z" />
    <path d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />{" "}
  </svg>
);

const heartIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1}
    stroke="currentColor"
    className={className}
  >
    <path d="M17.25,1.851A6.568,6.568,0,0,0,12,4.558,6.568,6.568,0,0,0,6.75,1.851,7.035,7.035,0,0,0,0,9.126c0,4.552,4.674,9.425,8.6,12.712a5.29,5.29,0,0,0,6.809,0c3.922-3.287,8.6-8.16,8.6-12.712A7.035,7.035,0,0,0,17.25,1.851ZM13.477,19.539a2.294,2.294,0,0,1-2.955,0C5.742,15.531,3,11.736,3,9.126A4.043,4.043,0,0,1,6.75,4.851,4.043,4.043,0,0,1,10.5,9.126a1.5,1.5,0,0,0,3,0,4.043,4.043,0,0,1,3.75-4.275A4.043,4.043,0,0,1,21,9.126C21,11.736,18.258,15.531,13.477,19.539Z" />
  </svg>
);

const timerIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1}
    stroke="currentColor"
    className={className}
  >
    <path d="m10 24h-10v-3h10zm-2-8h-8v3h8zm-2-5h-6v3h6zm5-5v6.621l3.439 3.44 2.122-2.122-2.561-2.56v-5.379zm1-6a12.006 12.006 0 0 0 -11.6 9h3.13a8.995 8.995 0 1 1 8.47 12v3a12 12 0 0 0 0-24z" />
  </svg>
);

const wrestlingIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="M9.5,19H8.386S9.667,17.291,9.7,17.223a2.52,2.52,0,0,0-.992-3.367l-2.06-1.211L8.82,8.683c1.21.482,2.726,1.059,3.148,1.219a1.5,1.5,0,0,0,1.064-2.8c-1.578-.6-3.978-1.532-4.37-1.728A3.491,3.491,0,0,0,3.994,6.881L2.37,9.83A3.5,3.5,0,0,0,3.884,14.5l2.869,1.683L4.807,18.764c-.019-.032-2.059-3.1-2.059-3.1a1.5,1.5,0,0,0-2.5,1.664L1.5,19.208A2.507,2.507,0,0,0,2.5,24h7A2.5,2.5,0,0,0,9.5,19ZM8,2.5a2.5,2.5,0,0,1,5,0A2.5,2.5,0,0,1,8,2.5Zm16,18A3.5,3.5,0,0,1,20.5,24h-5a1.5,1.5,0,0,1,0-3h1.073s-.967-1.944-.988-2l-.144,0H13.5a1.5,1.5,0,0,1,0-3h1.941A4.51,4.51,0,0,0,19.1,14.116l1.643-2.167a1.487,1.487,0,0,0-.331-2.026C20.385,9.9,19,8.655,19,8.655A5.592,5.592,0,0,1,13.5,14a1.5,1.5,0,0,1,0-3A2.563,2.563,0,0,0,16,8.543,6.855,6.855,0,0,0,14.8,4.9c-.009-.012-.3-.284-.3-.284a1.5,1.5,0,0,1,2-2.232l5.792,5.2a4.482,4.482,0,0,1,.867,6.143l-1.642,2.167a7.409,7.409,0,0,1-2.937,2.416L19.927,21H20.5a.5.5,0,0,0,.5-.5A1.5,1.5,0,0,1,24,20.5Z" />{" "}
  </svg>
);

const volleyballIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <path d="m23.992 12.16a11.9 11.9 0 0 1 -1.2 5.048.959.959 0 0 0 -.219.023 18.977 18.977 0 0 1 -5.6.769 19.1 19.1 0 0 1 -6.029-.8 1.478 1.478 0 0 1 -.619-2.394 11.959 11.959 0 0 1 1.078-1.057 1.462 1.462 0 0 1 1.422-.233 12.57 12.57 0 0 0 3.965.646 14.493 14.493 0 0 0 7.202-2.002zm-8.744-.136a1.488 1.488 0 0 0 1.717-1.635 17.118 17.118 0 0 0 -4.859-9.995.981.981 0 0 1 -.22-.388 11.908 11.908 0 0 0 -4.6.961 13.914 13.914 0 0 1 5.5 9.542 1.483 1.483 0 0 0 1.094 1.226 10.588 10.588 0 0 0 1.368.289zm5.674-.643a13.621 13.621 0 0 0 2.884-1.468 12.018 12.018 0 0 0 -9.114-9.6 19 19 0 0 1 4.255 9.864 1.474 1.474 0 0 0 1.975 1.204zm-4.122 8.619a20.992 20.992 0 0 1 -7.241-1.161 1.44 1.44 0 0 0 -1.884.975 11.823 11.823 0 0 0 -.359 3.231 11.984 11.984 0 0 0 13.936-3.407 21.072 21.072 0 0 1 -4.452.362zm-6-9.169a11.779 11.779 0 0 0 -.323-1.748 1.466 1.466 0 0 0 -2.368-.724 19.134 19.134 0 0 0 -5.9 9.583 6.65 6.65 0 0 0 -.093.842 12.08 12.08 0 0 0 3.2 3.171 13.914 13.914 0 0 1 4.939-9.868 1.458 1.458 0 0 0 .545-1.256zm-3.033-6.722a11.759 11.759 0 0 0 -2.467-2.061 11.937 11.937 0 0 0 -4.6 13.971 21.14 21.14 0 0 1 6.876-9.825 1.442 1.442 0 0 0 .191-2.085z" />
  </svg>
);

const otherIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor"
    className={className}
  >
    <circle cx="21.517" cy="12.066" r="2.5" />
    <circle cx="12" cy="12" r="2.5" />
    <circle cx="2.5" cy="12" r="2.5" />
  </svg>
);

const authIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
    strokeWidth={0}
    stroke="none"
    className={className}
  >
    <path d="m16,23.314c-1.252.444-2.598.686-4,.686s-2.748-.242-4-.686v-2.314c0-2.206,1.794-4,4-4s4,1.794,4,4v2.314ZM12,7c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Zm12,5c0,4.433-2.416,8.311-6,10.389v-1.389c0-3.309-2.691-6-6-6s-6,2.691-6,6v1.389C2.416,20.311,0,16.433,0,12,0,5.383,5.383,0,12,0s12,5.383,12,12Zm-8-3c0-2.206-1.794-4-4-4s-4,1.794-4,4,1.794,4,4,4,4-1.794,4-4Z" />
  </svg>
);

const logoutIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#8B0000"
    viewBox="0 0 24 24"
    strokeWidth={0}
    stroke="none"
    className={className}
  >
    <path d="M16,2.764V6.082a8,8,0,1,1-8,0V2.764a11,11,0,1,0,8,0Z" />
    <rect x="10.5" width="3" height="8" />
  </svg>
);

// Объект, где ключами являются названия иконок, а значениями - компоненты
const icons = {
  sun: SunIcon,
  moon: MoonIcon,
  burger: BurgerIcon,
  world: WorldIcon,
  search: SearchIcon,
  home: HomeIcon,
  chevronUp: chevronUpIcon,
  chevronDown: chevronDownIcon,
  winter: winterIcon,
  water: waterIcon,
  moto: motoIcon,
  tennis: tennisIcon,
  basketball: basketballIcon,
  hockey: hockeyIcon,
  figure: figureIcon,
  betting: bettingIcon,
  esports: esportsIcon,
  politic: politicsIcon,
  soccer: soccerIcon,
  boxing: boxingIcon,
  eye: eyeIcon,
  heart: heartIcon,
  timer: timerIcon,
  wrestling: wrestlingIcon,
  other: otherIcon,
  volleyball: volleyballIcon,
  auth: authIcon,
  logout: logoutIcon,
};

const IconsComponent = ({ name, ...props }) => {
  const Icon = icons[name];
  if (!Icon) return null;

  return <Icon {...props} />; // Пропсы, включая className, передаются SVG компоненту
};

export default IconsComponent;
