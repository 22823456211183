import React, { createContext, useContext, useState, useEffect } from "react";
import i18n from "../i18n"; // Убедитесь, что путь к файлу i18n.js корректен

export const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    () => localStorage.getItem("appLang") || "ru"
  );

  useEffect(() => {
    localStorage.setItem("appLang", language);
    // Обновление языка в i18next
    i18n.changeLanguage(language);
  }, [language]);

  const changeLanguage = (lang) => setLanguage(lang);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
