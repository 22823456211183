import React from "react";
import NavigationComponent from "../NavigationComponent/NavigationComponent";
import FooterComponent from "../FooterComponent/FooterComponent";

const SidebarComponent = () => {
  return (
    <div className="hidden lg:block fixed z-20 inset-0 top-[6.4rem] left-[max(0px,calc(50%-45rem))] right-auto w-[19rem] pb-10 pl-8 pr-6 overflow-y-auto">
      <NavigationComponent />
      {/* Прилипающий футер */}
      <div className="border-t border-slate-200 dark:border-slate-700">
        <FooterComponent />
      </div>
    </div>
  );
};

export default SidebarComponent;
