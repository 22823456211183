// frontend/src/utils/utils.js

// Функция для обрезки текста
export const truncateText = (text, maxLength) => {
  const strippedText = text.replace(/<[^>]+>/g, "");
  return strippedText.length > maxLength
    ? strippedText.substring(0, maxLength) + "…"
    : strippedText;
};

// Функция для форматирования даты
export const formatDate = (dateString) => {
  const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString("ru-RU", options);
};

// Функция для расчета времени чтения
export const calculateReadTime = (text) => {
  if (!text || typeof text !== "string") return "00:00";

  const words = text.trim().split(/\s+/).length;
  const totalMinutes = words / 150;
  const minutes = Math.floor(totalMinutes);
  const seconds = Math.floor((totalMinutes - minutes) * 60);

  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};
