import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PostPage from "./pages/PostPage";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent";
import SidebarComponent from "./components/SidebarComponent/SidebarComponent";
import FooterContentComponent from "./components/FooterComponent/FooterContentComponent";
import DesignComponent from "./components/DesignComponent/DesignComponent";
import CategoriesPage from "./pages/CategoriesPage";

function App() {
  return (
    <Router>
      <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-8">
        <HeaderComponent />
        <div className="">
          <DesignComponent />
          <SidebarComponent />
          <div id="masonry" className="lg:pl-[19.5rem]">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/news/:href" element={<PostPage />} />
              <Route path="/:link" element={<CategoriesPage />} />
            </Routes>
            <FooterContentComponent />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
