import React, { useState, useEffect, useRef } from "react";
import useAppSettings from "../../hooks/useAppSettings";
import IconsComponent from "../IconsComponent/IconsComponent";
import { ReactComponent as RussiaIcon } from "./russia.svg";
import { ReactComponent as KazakhstanIcon } from "./kazakhstan.svg";
import { ReactComponent as EnglishIcon } from "./english.svg";
import styles from "./LanguageSwitcher.module.scss";

const LanguageSwitcher = () => {
  const { language, changeLanguage } = useAppSettings();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null); // Добавляем useRef для кнопки
  const [buttonWidth, setButtonWidth] = useState(0); // Состояние для хранения ширины кнопки

  useEffect(() => {
    // Установка ширины кнопки при монтировании и при каждом изменении размеров окна
    const updateButtonWidth = () => {
      if (buttonRef.current) {
        setButtonWidth(buttonRef.current.offsetWidth); // Получаем текущую ширину кнопки
      }
    };

    window.addEventListener("resize", updateButtonWidth);
    updateButtonWidth(); // Вызываем сразу при монтировании

    return () => window.removeEventListener("resize", updateButtonWidth);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  useEffect(() => {
    // Чтение сохранённого языка из localStorage при монтировании компонента
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      changeLanguage(savedLanguage); // Если язык сохранён, используем его
    }
  }, [changeLanguage]); // зависимость от changeLanguage для обновления при изменении

  useEffect(() => {
    // Обновление атрибута lang в теге html при изменении языка
    document.documentElement.setAttribute("lang", language);

    // Сохранение выбранного языка в localStorage
    localStorage.setItem("language", language);
  }, [language]);

  const languages = [
    { code: "ru", name: "Русский", Icon: RussiaIcon },
    { code: "kz", name: "Қазақ", Icon: KazakhstanIcon },
    { code: "en", name: "English", Icon: EnglishIcon },
  ];

  const currentLanguage = languages.find((lang) => lang.code === language);

  return (
    <div className={styles.languageSwitcher} ref={dropdownRef}>
      <button
        ref={buttonRef} // Привязываем ref к кнопке
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center px-4 py-2 backdrop-blur transition-colors duration-500 supports-backdrop-blur:bg-white/95 dark:bg-slate-700/75 dark:text-white rounded-md shadow hover:bg-slate-900/15 dark:hover:bg-white/15"
      >
        <div className={styles.iconwrapper}>
          {currentLanguage &&
            React.createElement(currentLanguage.Icon, {
              className: "flag w-6 h-6 mr-2 -ml-3",
            })}
        </div>
        <span className="mr-2">{currentLanguage.name}</span>
        <IconsComponent
          name={isOpen ? "chevronUp" : "chevronDown"}
          className="w-3 h-3 transform transition-transform"
        />
      </button>
      {isOpen && (
        <ul
          className="z-50 absolute backdrop-blur transition-colors duration-500 bg-white/90 supports-backdrop-blur:bg-white/95 dark:bg-slate-700/75 dark:text-white rounded-md shadow divide-y divide-gray-200 dark:divide-gray-700 mt-1"
          style={{ width: `${buttonWidth}px` }} // Применяем динамическую ширину к списку
        >
          {languages
            .filter((lang) => lang.code !== language)
            .map((lang, index) => (
              <li
                key={lang.code}
                className={`px-4 py-2 hover:bg-slate-900/15 dark:hover:bg-white/15 flex items-center cursor-pointer text-sm ${
                  index > 0 ? "pt-2" : ""
                }`}
                onClick={() => {
                  changeLanguage(lang.code);
                  localStorage.setItem("language", lang.code); // Сохраняем выбор языка в localStorage
                  setIsOpen(false);
                }}
              >
                {React.createElement(lang.Icon, { className: "w-6 h-6 mr-2" })}
                <span>{lang.name}</span>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default React.memo(LanguageSwitcher);
