const LoaderComponent = () => {
  return (
    // Оверлей, занимающий весь экран
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-white dark:bg-slate-900/75 bg-opacity-75 flex justify-center items-center z-50">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-sky-500"></div>
    </div>
  );
};

export default LoaderComponent;
