// frontend/src/components/FooterComponent/FooterComponent.js
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"; // Импортируем useLocation

const FooterContentComponent = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="text-sm leading-6 mt-16 pb-[env(safe-area-inset-bottom)]">
      <div className="pt-10 pb-28 border-t border-slate-200 sm:flex justify-between text-slate-500 dark:border-slate-200/5">
        <div className="mb-6 sm:mb-0 sm:flex">
          {t("disclaimer")}{" "}
          <Link
            to="https://win2024.kz/"
            className="text-blue-600 hover:text-blue-800 dark:hover:text-blue-300 transition-colors duration-200"
          >
            <span className="ml-1">win2024.kz</span>
          </Link>
        </div>
        <div className="flex space-x-10 text-slate-400 dark:text-slate-500">
          {t("allRightsReserved", {
            year: currentYear,
            company: "ООО «Байконур Лидс»",
          })}
        </div>
      </div>
    </footer>
  );
};

export default FooterContentComponent;
