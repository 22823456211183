import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Импортируем useLocation
import { getCategories } from "../../services/firestoreService";
import IconsComponent from "../IconsComponent/IconsComponent";
import SearchComponent from "../SearchComponent/SearchComponent";
import useAppSettings from "../../hooks/useAppSettings";

const NavigationComponent = () => {
  const [categories, setCategories] = useState([]);
  const { language } = useAppSettings();
  const location = useLocation(); // Используем useLocation для получения текущего пути

  useEffect(() => {
    const fetchCategories = async () => {
      const loadedCategories = await getCategories();
      setCategories(loadedCategories);
    };

    fetchCategories();
  }, []);

  return (
    <nav id="nav" className="lg:text-sm lg:leading-6 relative">
      <SearchComponent />
      <ul>
        {categories.map((category) => {
          // Проверяем, активна ли текущая категория
          const isActive = location.pathname === `/${category.link}`;
          return (
            <li key={category.id}>
              <Link
                className={`group flex items-center lg:text-sm lg:leading-6 mb-4 font-medium  ${
                  isActive
                    ? "text-sky-500 font-bold dark:text-sky-400"
                    : "text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300"
                }`} // Условно добавляем классы для активной категории
                to={`/${category.link}`}
              >
                <div className="mr-4">
                  <IconsComponent name={category.icon} className="h-6 w-6" />
                </div>
                {category[language]}{" "}
                {/* Отображаем название на текущем языке */}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavigationComponent;
