import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage"; // Добавляем импорт для работы с Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyBugMN_QtpNBdKFZsB4jL7XCCUL-W-2qR4",
  authDomain: "betkznews.firebaseapp.com",
  projectId: "betkznews",
  storageBucket: "betkznews.appspot.com",
  messagingSenderId: "184238436381",
  appId: "1:184238436381:web:5af5af8bc1fb17f8cb16f4",
  measurementId: "G-R6XES67WBL",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();
const storage = firebase.storage(); // Создаём экземпляр Firebase Storage
const auth = firebase.auth();

const settings = { timestampsInSnapshots: true };
db.settings(settings);

// Enable experimentalForceLongPolling for Firestore
const firestoreConfig = {
  experimentalForceLongPolling: true,
};
const firestore = firebase.firestore(firebase.app());
firestore.settings(firestoreConfig);

export { firebase, db, storage, auth }; // Экспортируем storage вместе с остальными сервисами
