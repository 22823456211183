import React from "react";
import styles from "./SvgText.module.scss";
import useAppSettings from "../../hooks/useAppSettings";

const SvgText = () => {
  const { theme } = useAppSettings();

  return (
    <div
      className={`${
        theme === "dark" ? styles.darkTheme : ""
      } h-[calc(50%)] w-full ml-2`}
    >
      <svg
        className={styles.svgText}
        viewBox="0 0 86.38 17.53"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.94,10.96L13.56,.23h3.41l-3.04,17.06h-3.46l-1.99-10.05-1.97,10.05H3.05L0,.23H3.43l1.61,10.71L7.04,.23h2.89l2,10.72Z" />
        <path d="M22.43,17.3h-3.45V.23h3.45V17.3Z" />
        <path d="M37.25,17.3h-3.45l-5.04-11.19v11.19h-3.45V.23h3.45l5.05,11.2V.23h3.43V17.3Z" />
        <path d="M49.82,17.3h-10.05v-2.34l4.62-5.87c.59-.81,1.02-1.53,1.29-2.14s.42-1.2,.42-1.76c0-.76-.13-1.35-.39-1.78s-.64-.64-1.13-.64c-.54,0-.97,.25-1.28,.75s-.47,1.2-.47,2.11h-3.33c0-1.05,.22-2,.65-2.87s1.04-1.54,1.83-2.02,1.67-.72,2.67-.72c1.52,0,2.71,.42,3.54,1.26s1.26,2.03,1.26,3.58c0,.96-.23,1.94-.7,2.92s-1.31,2.21-2.53,3.65l-2.19,3.13h5.8v2.75Z" />
        <path d="M61.82,10.24c0,2.36-.43,4.16-1.28,5.41s-2.08,1.88-3.66,1.88-2.78-.61-3.64-1.82-1.3-2.96-1.33-5.22v-3.22c0-2.35,.43-4.15,1.28-5.4s2.07-1.87,3.67-1.87,2.82,.62,3.67,1.85,1.29,3,1.3,5.29v3.11Zm-3.33-3.46c0-1.34-.13-2.35-.4-3.02s-.68-1.01-1.24-1.01c-1.05,0-1.6,1.23-1.64,3.69v4.25c0,1.38,.13,2.4,.4,3.07s.69,1.01,1.26,1.01c.54,0,.94-.31,1.2-.94s.4-1.59,.42-2.89V6.79Z" />
        <path d="M74.1,17.3h-10.05v-2.34l4.62-5.87c.59-.81,1.02-1.53,1.29-2.14s.42-1.2,.42-1.76c0-.76-.13-1.35-.39-1.78s-.64-.64-1.13-.64c-.54,0-.97,.25-1.28,.75s-.47,1.2-.47,2.11h-3.33c0-1.05,.22-2,.65-2.87s1.04-1.54,1.83-2.02,1.67-.72,2.67-.72c1.52,0,2.71,.42,3.54,1.26s1.26,2.03,1.26,3.58c0,.96-.23,1.94-.7,2.92s-1.31,2.21-2.53,3.65l-2.19,3.13h5.8v2.75Z" />
        <path d="M84.93,10.85h1.45v2.75h-1.45v3.69h-3.32v-3.69h-5.65l-.15-2.14L81.6,.23h3.33V10.85Zm-5.89,0h2.58V5.14l-.07,.13-2.51,5.58Z" />
      </svg>
    </div>
  );
};

export default SvgText;
