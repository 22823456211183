import React, { useState } from "react";
import { Link } from "react-router-dom"; // Импортируем Link
import parse from "html-react-parser";
import IconsComponent from "../IconsComponent/IconsComponent";
import { truncateText, formatDate, calculateReadTime } from "../../utils/utils";

const CardComponent = ({ post, language }) => {
  const [hovered, setHovered] = useState(false);
  // Получаем и обрабатываем заголовок и описание для выбранного языка
  const title = post.title[language] || post.title["en"] || "";
  const description = post.desc[language] || post.desc["en"] || "";
  const text = post.text[language] || post.text["en"] || "";

  return (
    <Link
      to={`/news/${post.href}`}
      className="block"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="max-w-md mx-auto rounded-xl shadow-md hover:shadow-xl my-5 not-prose relative bg-slate-50 overflow-hidden dark:bg-slate-800/25 hover:bg-slate-100 hover:dark:bg-slate-800/70">
        {/* Изображение и иконки */}
        <div className="relative">
          <img
            className="w-full object-cover transition duration-300 ease-in-out filter hover:brightness-50 hover:blur-sm"
            src={post.featuredImage}
            alt={parse(truncateText(title, 40))}
            style={{ height: "auto", maxHeight: "48rem" }}
          />

          <div
            className={`absolute inset-0 flex items-center justify-center space-x-4 bg-slate-900/75 opacity-0 transition-opacity duration-300 ease-in-out ${
              hovered ? "opacity-100" : ""
            }`}
          >
            {/* Иконка просмотров и количество */}
            <div className="flex items-center text-white">
              <IconsComponent name="eye" className="h-6 w-6" />
              <span className="ml-2">
                {new Intl.NumberFormat().format(post.viewdCount)}
              </span>
            </div>

            {/* Иконка лайка и количество */}
            <button className="flex items-center text-white transition-colors duration-300">
              <IconsComponent
                name="heart"
                className="h-6 w-6 hover:fill-red-500"
              />
              <span className="ml-2">
                {new Intl.NumberFormat().format(post.like.count)}
              </span>
            </button>

            {/* Иконка таймера и длительность прочтения */}
            <div className="flex items-center text-white">
              <IconsComponent name="timer" className="h-6 w-6" />
              <span className="ml-2">{calculateReadTime(text)}</span>
            </div>
          </div>
        </div>
        <div className="p-6">
          <div className="text-xs text-grey-500 font-thin">
            {formatDate(post.date)}
          </div>
          <span className="block mt-1 text-lg leading-tight font-medium text-black dark:text-white pb-2">
            {parse(truncateText(title, 60))}
          </span>
          <span className="mt-2 text-gray-500 text-sm font-normal">
            {parse(truncateText(description, 200))}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default CardComponent;
